import { CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClassRoundedIcon from '@material-ui/icons/ClassRounded';
import HomeIcon from '@material-ui/icons/Home';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import ReplayIcon from '@material-ui/icons/Replay';
import ReportIcon from '@material-ui/icons/Report';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { useCallback, useEffect, useState } from 'react';
import { FaTruckLoading } from 'react-icons/fa';
import { TbGitFork } from 'react-icons/tb';

import { ConfirmationWithImageModal } from '../../../../../components/ConfirmationWithImageModal';
import {
  ConferenceStatus,
  PERMISSIONS,
  ShippingStatus,
} from '../../../../../constants';
import { useAuth } from '../../../../../hooks/auth';
import { useModal } from '../../../../../hooks/modal';
import { ShippingAddressModal } from '../../../../separation/components/OrderSeparation/ShippingAddressModal';
import {
  ISplitOrderItems,
  SplitShippingAddress,
  useSeparation,
} from '../../../../separation/hooks/separation';
import {
  IItems,
  IOrder,
  IOrderImage,
  IOrderTag,
  OrderImageTypeEnum,
  useOrder,
} from '../../../hooks/order';
import { ConfirmationModal } from '../../ConfirmationModal';
import { OccurrenceDialog } from '../../OccurrenceDialog';
import { HiReceiptRefund } from 'react-icons/hi';

interface IProps {
  order: IOrder;
  type: 'order' | 'separation';
  separationItems?: IItems[];
  fractionedItemsId?: number[];
  clearFractionedItemsId?: () => void;
  onShippingStatusChange?: (orderId: number, status: ShippingStatus) => void;
  onConferenceChange?: (
    orderId: number,
    status: ConferenceStatus,
    items: IItems[]
  ) => void;
  onReferencedItemsChange?: (orderId: number, itemsId: number[]) => void;
  refreshFractionedItems?: () => void;
}

export const RowActionButtons = ({
  order,
  onShippingStatusChange,
  onConferenceChange,
  type,
  separationItems,
  fractionedItemsId = [],
  clearFractionedItemsId,
  onReferencedItemsChange,
  refreshFractionedItems,
}: IProps) => {
  const { open } = useModal();
  const {
    data: { permissions },
  } = useAuth();
  const {
    loadOrderTag,
    generatePostCode,
    printMailingLabel,
    updateShippingStatus,
    updateStatusCode,
    changeOperation,
    resendOrder,
    cancelOrder,
    regenerateOrderNFe,
  } = useOrder();
  const { cancelSeparatedOrders, splitOrderItems } = useSeparation();
  const [loading, setLoading] = useState(true);
  const [openOccurrenceDialog, setOpenOccurrenceDialog] = useState(false);
  const [orderId, setOrderId] = useState<number | null>(null);
  const [orderTag, setOrderTag] = useState<IOrderTag>({
    tagJson: '',
    tagCode: '',
    orderNumber: '',
    contractNumber: '',
  });

  useEffect(() => {
    async function getOrderTag() {
      setLoading(true);
      setOrderTag(await loadOrderTag(order.id));
      setLoading(false);
    }

    getOrderTag();
  }, [loadOrderTag, order.id]);

  const isMarkAsSeparateDisabled = useCallback(() => {
    if (
      permissions.includes(PERMISSIONS.ORDER_MARK_SEPARATE) &&
      order.shippingStatus === 'unpacked'
    ) {
      if (type === 'separation') {
        if (
          (order.conferenceStatus === 'unchecked' ||
            order.conferenceStatus === 'partially-conferred') &&
          separationItems!.find((item) => item.isChecked)
        ) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    }
    return true;
  }, [
    permissions,
    order.shippingStatus,
    order.conferenceStatus,
    type,
    separationItems,
  ]);

  const isUnmarkAsSeparateDisabled = useCallback(() => {
    if (
      order.shippingStatus === 'unpacked' &&
      order.items.find((item) => item.isChecked)
    )
      return false;

    return true;
  }, [order.items, order.shippingStatus]);

  const isDisabledNotifyShipping = useCallback(() => {
    if (
      permissions.includes(PERMISSIONS.ORDER_NOTIFY_SHIPPING) &&
      order.shippingStatus === 'unshipped' &&
      (order.shippingType === 'table' ||
        order.shippingType === 'motoboy' ||
        order.shippingType === 'transportadora' ||
        order.shippingType === 'proprio' ||
        ((order.shippingType === 'correios' ||
          order.shippingType === 'correios-sedex' ||
          order.shippingType === 'correios-pac') &&
          orderTag.tagCode &&
          orderTag.tagCode !== ''))
    )
      return false;

    return true;
  }, [order.shippingStatus, order.shippingType, orderTag.tagCode, permissions]);

  const isDisabledResendOrder = useCallback(() => {
    if (order.statusCode === 'delivered') return false;

    return true;
  }, [order.statusCode]);

  const isDisabledGenerateNF = useCallback(() => {
    if (
      order.operationType === 'delivery' &&
      order.occurrence === true &&
      order.occurrenceDescription?.includes('Erro gerar NF')
    )
      return false;

    return true;
  }, [order.statusCode]);

  const isDisabledCancelOrder = useCallback(() => {
    if (
      order.statusCode !== 'delivered' &&
      order.statusCode !== 'on-planned-route' &&
      order.statusCode !== 'on-route' &&
      order.statusCode !== 'cancelled'
    )
      return false;

    return true;
  }, [order.statusCode]);

  const isDisabledChangeOperation = useCallback(() => {
    if (
      order.statusCode !== 'delivered' &&
      order.statusCode !== 'on-planned-route' &&
      order.statusCode !== 'on-route' &&
      order.statusCode !== 'cancelled'
    )
      return false;

    return true;
  }, [order.statusCode]);

  const isDisabledOpenOccurrence = useCallback(() => {
    if (order.statusCode !== 'delivered' && order.statusCode !== 'cancelled')
      return false;

    return true;
  }, [order.statusCode]);

  const isDisabledMarkDelivered = useCallback(() => {
    if (
      permissions.includes(PERMISSIONS.ORDER_MARK_DELIVERED) &&
      order.statusCode !== 'delivered' &&
      order.statusCode !== 'cancelled'
    ) {
      return false;
    }

    return true;
  }, [order.statusCode, permissions]);

  const isDisabledPrintLabel = useCallback(() => {
    if (orderTag.tagCode && orderTag.tagCode !== '') return false;

    return true;
  }, [orderTag.tagCode]);

  const isDisabledGenerateTag = useCallback(() => {
    if (
      order.shippingType === 'correios' ||
      order.shippingType === 'correios-sedex' ||
      order.shippingType === 'correios-pac'
    )
      return false;

    return true;
  }, [order.shippingType]);

  const handleGenerateTag = useCallback(async () => {
    setLoading(true);

    generatePostCode(order.id);

    setTimeout(async () => {
      setOrderTag(await loadOrderTag(order.id));

      setLoading(false);
    }, 3600);
  }, [order, generatePostCode, loadOrderTag]);

  const handlePrintLabelAction = async () => {
    setLoading(true);
    setOrderTag(await loadOrderTag(order.id));

    await printMailingLabel(order.id);

    setLoading(false);
  };

  const handleShippingStatusChange = useCallback(
    async (status: 'shipped' | 'unshipped') => {
      setLoading(true);

      if (status === 'unshipped') {
        if (type === 'order') {
          order.shippingStatus = status;

          const items = order.items.map((item) => ({
            ...item,
            isChecked: true,
          }));

          await updateShippingStatus(order.id, status, items);
        } else if (
          separationItems &&
          onConferenceChange &&
          onShippingStatusChange
        ) {
          const isAllItemsChecked = separationItems.find((i) => !i.isChecked)
            ? false
            : true;

          isAllItemsChecked && onShippingStatusChange(order.id, status);

          onConferenceChange(
            order.id,
            isAllItemsChecked ? 'conferred' : 'partially-conferred',
            separationItems
          );

          await updateShippingStatus(order.id, status, separationItems);
        }
      } else {
        await updateShippingStatus(order.id, status);
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    },
    [
      onConferenceChange,
      onShippingStatusChange,
      order,
      separationItems,
      type,
      updateShippingStatus,
    ]
  );

  const handleUnmarkAsSeparate = useCallback(async () => {
    const itemsUnmarked = order.items.map((item) => ({
      ...item,
      isChecked: false,
    }));

    if (onConferenceChange)
      onConferenceChange(order.id, 'unchecked', itemsUnmarked);

    await cancelSeparatedOrders(order.id);
  }, [order.items, order.id, onConferenceChange, cancelSeparatedOrders]);

  const handleOpenOccurrence = useCallback((id: number) => {
    setOpenOccurrenceDialog(true);
    setOrderId(id);
  }, []);

  const handleCloseOccurrence = useCallback(() => {
    setOpenOccurrenceDialog(false);
    setOrderId(null);
  }, []);

  const handleMarkDelivered = async (images: IOrderImage[]) => {
    setLoading(true);
    order.statusCode = 'delivered';
    await updateStatusCode({ orderId: order.id, images });
    setTimeout(async () => {
      setLoading(false);
    }, 2000);
  };

  const handleChangeOperation = async () => {
    setLoading(true);
    await changeOperation(order.id);
    setTimeout(async () => {
      setLoading(false);
    }, 2000);
  };

  const handleResendOrder = async () => {
    setLoading(true);
    await resendOrder(order.id);
    setTimeout(async () => {
      setLoading(false);
    }, 2000);
  };

  const handleRegenerateNFe = async () => {
    setLoading(true);
    await regenerateOrderNFe(order.id);
    setTimeout(async () => {
      setLoading(false);
    }, 2000);
  };

  const handleCancelOrder = async () => {
    order.statusCode = 'cancelled';
    setLoading(true);
    await cancelOrder(order.id);
    setTimeout(async () => {
      setLoading(false);
    }, 2000);
  };

  const handleOrderSplitting = useCallback(
    async (shipping: {
      address: SplitShippingAddress;
      previsionDate: string | null;
      observation: string | null;
    }) => {
      const data: ISplitOrderItems = {
        orderId: order.id,
        itemsId: fractionedItemsId,
        previsionDate: shipping.previsionDate,
        shippingAddress: shipping.address,
        observation: shipping.observation,
      };

      if (type === 'order') {
        data.operation = 'collect';
      }

      await splitOrderItems(data);

      if (onReferencedItemsChange)
        onReferencedItemsChange(order.id, fractionedItemsId);
      if (clearFractionedItemsId) clearFractionedItemsId();
      if (refreshFractionedItems) refreshFractionedItems();
    },
    [
      clearFractionedItemsId,
      fractionedItemsId,
      onReferencedItemsChange,
      order.id,
      refreshFractionedItems,
      splitOrderItems,
      type,
    ]
  );

  return (
    <>
      <OccurrenceDialog
        id={orderId}
        open={openOccurrenceDialog}
        onClose={handleCloseOccurrence}
      />

      <Grid container justifyContent="center">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Tooltip title="Marcar como Separado" placement="top">
              <span>
                <IconButton
                  color="inherit"
                  aria-label="Marcar como Separado"
                  onClick={() =>
                    open(ConfirmationModal, {
                      title: 'Deseja marcar como separado?',
                      onSubmit: () => handleShippingStatusChange('unshipped'),
                    })
                  }
                  disabled={isMarkAsSeparateDisabled()}
                >
                  <CheckCircleIcon />
                </IconButton>
              </span>
            </Tooltip>

            {type === 'order' && (
              <Tooltip title="Criar Pedido de Coleta" placement="top">
                <span>
                  <IconButton
                    color="inherit"
                    aria-label="Criar Pedido de Coleta"
                    onClick={() =>
                      open(ShippingAddressModal, {
                        title: 'Criar Pedido de Coleta',
                        order,
                        fractionedItemsId,
                        currentShippingAddress: order.shippingAddress,
                        onSubmit: handleOrderSplitting,
                      })
                    }
                    disabled={fractionedItemsId.length === 0}
                  >
                    <FaTruckLoading />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {type === 'separation' && (
              <>
                <Tooltip title="Desmarcar todos como Separado" placement="top">
                  <span>
                    <IconButton
                      color="inherit"
                      aria-label="Desmarcar todos como Separado"
                      onClick={() =>
                        open(ConfirmationModal, {
                          title: 'Deseja desmarcar todos como separado?',
                          onSubmit: handleUnmarkAsSeparate,
                        })
                      }
                      disabled={isUnmarkAsSeparateDisabled()}
                    >
                      <CancelIcon />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip
                  title="Fracionar produtos selecionados"
                  placement="top"
                >
                  <span>
                    <IconButton
                      color="inherit"
                      aria-label="Fracionar produtos selecionados"
                      onClick={() =>
                        open(ShippingAddressModal, {
                          title: 'Fracionar produtos selecionados',
                          order,
                          fractionedItemsId,
                          currentShippingAddress: order.shippingAddress,
                          onSubmit: handleOrderSplitting,
                          disablePrevisionDate: true,
                        })
                      }
                      disabled={fractionedItemsId.length === 0}
                    >
                      <TbGitFork size={24} />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            )}

            <Tooltip title="Gerar código Correio" placement="top">
              <span>
                <IconButton
                  color="inherit"
                  aria-label="Gerar código Correio"
                  onClick={() =>
                    open(ConfirmationModal, {
                      title: 'Deseja gerar código do correio?',
                      onSubmit: handleGenerateTag,
                    })
                  }
                  disabled={isDisabledGenerateTag()}
                >
                  <ClassRoundedIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Imprimir Etiqueta" placement="top">
              <span>
                <IconButton
                  color="inherit"
                  aria-label="Imprimir Etiqueta"
                  onClick={() =>
                    open(ConfirmationModal, {
                      title: 'Deseja imprimir etiqueta?',
                      onSubmit: handlePrintLabelAction,
                    })
                  }
                  disabled={isDisabledPrintLabel()}
                >
                  <PrintRoundedIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Notificar Envio" placement="top">
              <span>
                <IconButton
                  color="inherit"
                  aria-label="Notificar Envio"
                  onClick={() =>
                    open(ConfirmationModal, {
                      title: 'Deseja notificar envio?',
                      onSubmit: () => handleShippingStatusChange('shipped'),
                    })
                  }
                  disabled={isDisabledNotifyShipping()}
                >
                  <SendRoundedIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Gerar NF de Remessa" placement="top">
              <span>
                <IconButton
                  color="inherit"
                  aria-label="Gerar NF de Remessa"
                  onClick={() =>
                    open(ConfirmationModal, {
                      title: 'Deseja Gerar NF de Remessa?',
                      onSubmit: handleRegenerateNFe,
                    })
                  }
                  disabled={isDisabledGenerateNF()}
                >
                  <HiReceiptRefund />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Reenviar" placement="top">
              <span>
                <IconButton
                  color="inherit"
                  aria-label="Reenviar"
                  onClick={() =>
                    open(ConfirmationModal, {
                      title: 'Deseja reenviar?',
                      onSubmit: handleResendOrder,
                    })
                  }
                  disabled={isDisabledResendOrder()}
                >
                  <ReplayIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Ocorrência" placement="top">
              <span>
                <IconButton
                  color="inherit"
                  aria-label="Ocorrência"
                  onClick={() => handleOpenOccurrence(order.id)}
                  disabled={isDisabledOpenOccurrence()}
                >
                  <ReportIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Marcar como Concluído" placement="top">
              <span>
                <IconButton
                  color="inherit"
                  aria-label="Marcar como Concluído"
                  onClick={() =>
                    open(ConfirmationWithImageModal, {
                      title: 'Deseja marcar como Concluído?',
                      type: OrderImageTypeEnum.DELIVERED,
                      onSubmit: async (images: IOrderImage[]) =>
                        await handleMarkDelivered(images),
                    })
                  }
                  disabled={isDisabledMarkDelivered()}
                >
                  <HomeIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Trocar Operação" placement="top">
              <span>
                <IconButton
                  color="inherit"
                  aria-label="Trocar Operação"
                  onClick={() =>
                    open(ConfirmationModal, {
                      title: 'Deseja trocar o tipo de operação?',
                      onSubmit: handleChangeOperation,
                    })
                  }
                  disabled={isDisabledChangeOperation()}
                >
                  <SwapHorizIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Cancelar" placement="top">
              <span>
                <IconButton
                  color="inherit"
                  aria-label="Cancelar"
                  onClick={() =>
                    open(ConfirmationModal, {
                      title: 'Deseja cancelar o pedido?',
                      onSubmit: handleCancelOrder,
                    })
                  }
                  disabled={isDisabledCancelOrder()}
                >
                  <CancelIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </Grid>
    </>
  );
};
